import React from "react";
import tree from "./tree.gif";
import "./App.css";

function App() {
  const Snowflakes = require("magic-snowflakes");
  const christmas = require("merry-christmas");
  const user = decodeURI(window.location.pathname.substring(1));
  Snowflakes();
  return (
    <div className="App">
      <header className="App-header">
        <img src={tree} className="App-logo" alt="logo" />
        <p>{user ? "Dear " + user.charAt(0).toUpperCase() + user.slice(1) + ", " : ""}Wishing you a Merry Christmas!</p>
        <p>{christmas.random()}</p>
      </header>
    </div>
  );
}

export default App;
